import { domain } from '@/lib/utils'
import { type Metadata } from 'next'

const PAGE_POSTFIX = '\u2002|\u2002GesetzeFinden.at'

export interface MetaProps {
  /** Title of the page */
  title?: string
  /** Description of the page */
  description?: string
  ogDescription?: string
  /** Absolute image path */
  image?: string | null
  canonical?: string
  titlePostfix?: string
  imageTemplate?: 'generic' | 'norm'
  normType?: 'law' | 'decision' | 'clause'
}

export function getMetadata ({ title, description, ogDescription, image, canonical, titlePostfix = PAGE_POSTFIX, imageTemplate = 'generic', normType }: MetaProps, currentPath: string): Metadata {
  image = image === undefined
    ? (title && `/api/og?title=${encodeURIComponent(title || '')}&subTitle=${encodeURIComponent(ogDescription ?? description ?? '')}&template=${imageTemplate}${normType ? `&type=${normType}` : ''}`)
    : undefined

  return {
    applicationName: 'GesetzeFinden.at',
    metadataBase: new URL(domain),
    title: `${title ?? ''}${titlePostfix}`,
    description,
    alternates: {
      canonical: canonical ?? currentPath,
    },
    openGraph: {
      type: 'website',
      url: currentPath,
      title,
      description: ogDescription ?? description,

      // Use object-spread here, because otherwise custom `opengraph-image.png` images don't get picked up in the app dir
      // Even when the property is set to `undefined`..., so don't set it at all in this case (via object-spread)
      ...(image ? { images: image } : {}),
    },

    twitter: {
      card: 'summary_large_image',
      title,
      description,
      creator: '@GesetzeFindenAT',
      ...(image ? { images: image } : {}),
    },
  }
}
