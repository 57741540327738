import Image from 'next/legacy/image'
import logoVertical from '@/public/logo-vertical.png'
import Button from '@/components/Button/Button'
import { Stack, Clamp, Row } from '@christiankaindl/lyts'
import { type FC } from 'react'
import * as styles from './Footer.css'
import { Instagram, Linkedin, Twitter } from 'lucide-react'

const Footer: FC = function () {
  return (
    <Clamp
      asChild
      clamp='900px'
      className={styles.root}
      // xAlign='center'
    >
      <footer>
        <Row expandChildren wrap gap={3} yAlign='start' xAlign='center'>
          <Stack gap={0.25} xAlign='center'>
            <Image
              src={logoVertical}
              width={logoVertical.width / 2}
              height={logoVertical.height / 2}
              alt='Logo'
            />
          </Stack>
          <Row gap={2} wrap yAlign='start' xAlign='center'>
            <Stack gap={0.5}>
              <Button href='/amiga/entscheidungsmonitoring' variant='flat' size='small'>
                AmigaAI Monitoring
              </Button>
              <Button href='/legal-tech' variant='flat' size='small'>
                Legal Tech
              </Button>
              <hr style={{ marginLeft: 9 }} />
              <Button href='/blog' variant='flat' size='small'>
                Blog
              </Button>
              <Button href='/datenquellen' variant='flat' size='small'>
                Datenquellen
              </Button>
              <Button href='/team' variant='flat' size='small'>
                Team
              </Button>
              <Button href='/kontakt' variant='flat' size='small'>
                Kontakt
              </Button>
            </Stack>
            <Stack gap={0.5}>
              <Button href='/bundesrecht' variant='flat' size='small'>
                Bundesrecht
              </Button>
              <Button style={{ fontWeight: '400' }} href='/bundesrecht/bundesverfassungsgesetze' variant='flat' size='small'>
                Verfassungsgesetze
              </Button>
              <Button style={{ fontWeight: '400' }} href='/bundesrecht/bundesgesetze' variant='flat' size='small'>
                Bundesgesetze
              </Button>
              <Button style={{ fontWeight: '400' }} href='/bundesrecht/verordnungen' variant='flat' size='small'>
                Verordnungen
              </Button>
              <hr style={{ marginLeft: 9 }} />
              <Button href='/judikatur' variant='flat' size='small'>
                Judikatur
              </Button>
            </Stack>
            <Stack gap={0.5}>
              <Button href='https://www.linkedin.com/company/gesetzefinden' variant='flat' size='small'>
                <Linkedin size={16} />
                LinkedIn
              </Button>
              <Button href='https://twitter.com/gesetzefindenat' variant='flat' size='small'>
                <Twitter size={16} />
                Twitter
              </Button>
              <Button href='https://www.instagram.com/gesetzefinden.at/' variant='flat' size='small'>
                <Instagram size={16} />
                Instagram
              </Button>
              <hr style={{ marginLeft: 9 }} />
              <Button href='/agb' variant='flat' size='small' title='Allgemeine Geschäftsbedingungen'>
                AGB
              </Button>
              <Button href='/datenschutz' variant='flat' size='small'>
                Datenschutzerklärung
              </Button>
              <Button href='/impressum' variant='flat' size='small'>
                Impressum
              </Button>
              <Button href='/haftungsausschluss' variant='flat' size='small'>
                Haftungsausschluss
              </Button>
            </Stack>
          </Row>
        </Row>
      </footer>
    </Clamp>
  )
}

export default Footer
